import Vue from 'vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en' // lang i18n
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import moment from 'moment'
import App from './App'
import 'nprogress/nprogress.css'
import 'default-passive-events'

moment.locale('zh-cn', {
  week: {
    dow: 1
  }
})
Vue.use(VXETable)

Vue.use(ElementUI, locale)
Vue.config.productionTip = false
Vue.directive('title', {
  inserted (el, binding) {
    el.onmouseover = (e) => {
      const element = document.getElementsByClassName('mytitle')
      if (!element[0]) {
        const div = document.createElement('div')
        div.className = 'mytitle'
        div.style.left = (e.clientX + 10) + 'px'
        div.style.top = (e.clientY + 10) + 'px'
        div.style.zIndex = 5
        div.style.position = 'fixed'
        div.style.backgroundColor = 'white'
        div.style.color = '#C2CCE2'
        div.style.border = '1px solid #C2CCE2'
        div.style.padding = '5px 10px'
        div.style.borderRadius = '7px'
        div.style.cursor = 'pointer'
        div.style.fontSize = '13px'
        div.style.fontWeight = 'normal'
        div.style.backgroundColor = 'rgba(255,255,255,0.9)'
        div.style.boxShadow = '1px 1px 8px 0px rgba(176, 190, 226, 0.45);'
        const i = document.createElement('i')
        i.className = 'el-icon-question'
        i.style.marginRight = '5px'
        div.appendChild(i)
        div.append(binding.value)
        el.before(div)
      } else {
        element[0].style.display = 'block'
      }
    }
    el.onmouseout = (e) => {
      const element = document.getElementsByClassName('mytitle')
      element[0].remove()
    }
    el.onmousemove = (e) => {
      const element = document.getElementsByClassName('mytitle')
      element[0].style.left = (e.clientX + 10) + 'px'
      element[0].style.top = (e.clientY + 10) + 'px'
    }
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
