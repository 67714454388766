import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    name: '',
    avatar: '', // 头像
    roles: [], // 角色
    matrix: '1', // 矩阵
    user: {},
    childrenTitle: ''
  },
  getters: {
    avatar: state => state.avatar,
    name: state => state.name,
    roles: state => state.roles,
    matrix: state => state.matrix,
    user: state => state.user,
    childrenTitle: state => state.childrenTitle
  },
  mutations: {
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_MATRIX: (state, matrix) => {
      state.matrix = matrix
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_CHILDREN_TITLE: (state, childrenTitle) => {
      state.childrenTitle = childrenTitle
    }
  },
  actions: {},
  modules: {}
})
