import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/views/user/main/Main.vue'),
    redirect: '/matrix',
    children: [{
      path: '/matrix',
      name: 'matrix',
      meta: {
        requireAuth: true,
        title: '星火传媒'
      },
      component: () => import('@/views/user/matrix/Matrix.vue')
    }]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/user/login/Login.vue')
  },
  {
    path: '/adminLogin',
    name: 'adminLogin',
    component: () => import('@/views/admin/login/Login.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: '/admin/operating',
    meta: {
      requireAuth: true
    },
    component: () => import('@/views/admin/common/MainCommon.vue'),
    children: [
      {
        path: '/admin/operating',
        name: 'operating',
        redirect: '/admin/operating/accountManager',
        meta: {
          requireAuth: true
        },
        component: () => import('@/components/admin/operating/Operating.vue'),
        children: [
          {
            path: '/admin/operating/accountManager',
            name: 'accountManager',
            meta: {
              requireAuth: true
            },
            component: () => import('@/views/admin/operatingMatrix/OperatingMatrix.vue')
          },
          {
            path: '/admin/operating/dataLook',
            name: 'dataLook',
            meta: {
              requireAuth: true
            },
            component: () => import('@/components/admin/operating/dataLook/DataLook.vue')
          }
        ]
      }, {
        path: '/admin/mcnMatrix',
        name: 'mcnMatrix',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/admin/mcnMatrix/mcnMatrix.vue')
      },
      {
        path: '/admin/mediaMatrix',
        name: 'mediaMatrix',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/admin/mediaMatrix/mediaMatrix.vue')
      },
      {
        path: '/admin/mMatrix',
        name: 'mMatrix',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/admin/mMatrix/mMatrix.vue')
      },
      {
        path: '/admin/platform/platform',
        name: 'platform',
        meta: {
          requireAuth: true
        },
        component: () => import('@/components/admin/platform/platform.vue')
      },
      {
        path: '/admin/platform/region',
        name: 'region',
        meta: {
          requireAuth: true
        },
        component: () => import('@/components/admin/platform/region.vue')
      },
      {
        path: '/admin/user/plain',
        name: 'plainUser',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/admin/user/plain-user')
      },
      {
        path: '/admin/user/admin',
        name: 'adminUser',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/admin/user/admin-user')
      }
    ]
  },
  {
    path: '/agree',
    name: 'agree',
    component: () => import('@/views/user/agree/Agree.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '星火传媒'
  }
  if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
    if (getToken()) {
      next()
    } else {
      Message.closeAll()
      Message({
        message: '请先登录',
        type: 'error',
        duration: 5000
      })
      if (!to.path.startsWith('/admin')) {
        next({ path: '/login' })
      } else {
        next({ path: '/adminLogin' })
      }
    }
  } else {
    next()
  }
})

// 解决使用router.push路由重复报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
